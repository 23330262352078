import { createSlice } from '@reduxjs/toolkit'

export const toastifySlice = createSlice({
  name: 'toastify',
  initialState: {
    message: '',
    type:'success',
    uuid: performance.now()
  },
  reducers: {
    setToaster: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type || state.type;
      state.uuid = performance.now()
    },
  },
})

// Action creators are generated for each case reducer function
export const { setToaster } = toastifySlice.actions

export default toastifySlice.reducer