import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/userSlice';
import invoiceReducer from './slices/invoiceSlice';
import toastifyReducer from './slices/toastifySlice';

export default configureStore({
  reducer: {
    user: userReducer,
    invoice: invoiceReducer,
    toastify: toastifyReducer
  },
})