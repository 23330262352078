import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userMeta: {},
    sideNavOpen: false
  },
  reducers: {
    setuserMeta: (state, action) => {
      state.userMeta = action.payload
    },
    setSideNavOpen: (state, action) =>{
        state.sideNavOpen = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setuserMeta, setSideNavOpen } = userSlice.actions

export default userSlice.reducer