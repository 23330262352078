import { createSlice } from '@reduxjs/toolkit'

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    income: [],
    expenses: [],
    isLoadingIncome: false,
    isLoadingExpense: false
  },
  reducers: {
    getIncomeBegins : (state) =>{
      state.isLoadingIncome = true
    },
    setIncome: (state, action) => {
      state.isLoadingIncome = false;
      state.income = action.payload
    },
    setExpenses: (state, action) => {
      state.expenses = action.payload;
      state.isLoadingExpense = false;
    },
    // Expense
    getExpenseBegins: (state) =>{
      state.isLoadingExpense = true
    },
    updateInvoiceAfterSave: (state, action) =>{
      const stateData = action.payload.revenue_type === 'INCOME' ? [...state.income] : [...state.expenses];
      state[action.payload.revenue_type === 'INCOME' ? 'income': 'expenses'] = stateData.map(data => {
        if(Number(data.id) === Number(action.payload.id)){
          return {...action.payload, created_date: new Date(action.payload.created_date).toISOString()}
        }
        return data;
      })
    },
    deleteInvoiceEntry: (state, action) =>{
      const stateData = [...state[action.payload.type]];
      state[action.payload.type] = stateData.filter(data => Number(data.id) !== Number(action.payload.id))
    },
    updateNewInvoice: (state, action) =>{
      const stateData = action.payload.revenue_type === 'INCOME' ? [...state.income] : [...state.expenses];
      state[action.payload.revenue_type === 'INCOME' ? 'income': 'expenses'] = [...stateData, action.payload]
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setIncome,
  setExpenses,
  getIncomeBegins,
  getExpenseBegins,
  updateInvoiceAfterSave,
  deleteInvoiceEntry,
  updateNewInvoice } = invoiceSlice.actions

export default invoiceSlice.reducer