import axios from 'axios';
// const API_URL = `http://thenoorkonamtemple.in/wp-app/wp-json/v1/api/`;
const API_URL = process.env.NODE_ENV !== 'production' ? `http://localhost/wordpress/wp-app/wp-json/v1/api/` : `http://thenoorkonamtemple.in/wp-app/wp-json/v1/api/`;

export const setAxios = (token='') => {
	// Add a request interceptor
	axios.interceptors.request.use((config) => {
	// set auth token
    config.headers  = config.headers ?? {};
		let authToken = token;
        if (authToken &&
            authToken !== '' &&
            authToken !== 'undefined' &&
            authToken !== null &&
            authToken !== 'null' && config) {
                config.headers['Authorization'] = `Bearer ${authToken}`;
        }
		return config;
	}, function (error) {
		return Promise.reject(error)
	})

	// axios response interceptor
axios.interceptors.response.use((response) =>response, (error) =>{
		switch (error.response?.status) {
			case 400:			
				// window.location.href = `${window.location.origin}/fliqweb/`;
				break;
			case 401:
				// Unauthorized
				// clear local storage data, then redirect to login screen
				window.location.href = `${window.location.origin}/`;
				setAxios('')
				localStorage.clear()
				break;
			case 403:
				if(error.response.data.code === 'jwt_auth_invalid_token'){
					localStorage.clear();
					window.location.href = `${window.location.origin}/`;
				}				
				break;
			case 404:
				// Not Found
				break;
			case 405:
				// Method Not Allowed
				// show error page
				break;
			case 408:
				// Request Timeout
				break;
			case 409:
				// Conflict
				break;
			case 429:
				// Too Many Requests
				break;
			case 500:
				
				return Promise.reject(error)
				// break;
			case 501:
				// Not Implemented
				break;
			case 502:
				// Bad Gateway
				break;
			case 503:
				
				break;
			case 504:
				// Gateway Timeout
				break;
			case 505:
				// HTTP Version Not Supported
				break;
			case 598:
				// Network read timeout error
				break;
			case 599:
				// Network connect timeout error
				break;
			default:
				break;
		}
		return Promise.reject(error)
	})
}

export const getApi = (url, params) => axios.get(`${API_URL}${url}`, params);
export const postApi = (url, data, config={}) => axios.post(`${API_URL}${url}`, data, config);
export const putApi = (url, data) => axios.put(`${API_URL}${url}`, data);
export const deleteApi = (url) => axios.delete(`${API_URL}${url}`);