import React, { createContext, useContext, useEffect, useState } from 'react';
import { userLogin } from '../actions/auth';
import { setAxios } from '../utils/axios';
import { setToaster } from 'slices/toastifySlice';
import { setuserMeta } from 'slices/userSlice';
import { useDispatch } from 'react-redux';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const dispatch = useDispatch()
  const [user, setUser] = useState(null);

  const login = async (loginPayload, cb) => {
    try {
      const token = await userLogin(loginPayload);
      // Replace this with your authentication logic (e.g., API call)
      if(token && token?.status === 401){
        dispatch(setToaster({message: 'Invalid username/password', type: 'error' }));
        return false
      }
        setUser(token.token.user);
        setAxios(token.token.token)
        dispatch(setuserMeta(token.token.user))
        localStorage.setItem('_auth_token', JSON.stringify(token.token));
        cb()      
    } catch (error) {
      
    }
  };

  const logout = (cb) => {
    setUser(null);
    localStorage.removeItem('_auth_token');
    dispatch(setuserMeta({}));
    window.location.href="/"
  };

  const isAuthenticated = () => {
    const token = JSON.parse(localStorage.getItem('_auth_token'));
    return !!token
  };

  useEffect(()=>{
    if(localStorage.getItem('_auth_token')){
        const token = JSON.parse(localStorage.getItem('_auth_token'));
        setAxios(token.token)
        dispatch(setuserMeta(token.user))
        setUser(token.user);
    }
  },[])
  
  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
