import { postApi } from "../utils/axios";
import {setuserMeta} from './../slices/userSlice';


export const userLogin=async(payload)=>{
    try {
        const response = await postApi(`login`, payload);
        setuserMeta(response.data)
        return {token: response.data}
    } catch (error) {
        return error.response
    }
}